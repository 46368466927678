// Imports

import { Dialog } from "@blueprintjs/core";

import InstagramButton from "../buttons/Instagram";
import DiscordButton from "../buttons/Discord";
import TwitterButton from "../buttons/Twitter";
import Label from "../Label";

// Success Dialog

const SuccessDialog = ({ open, onClose, mintAmount }) => {
    const handleOnClose = (e) => {
        if (typeof onClose === "function") {
            onClose();
        }
    };

    const mintNumber = mintAmount > 1 ? mintAmount : "a";
    const mintText =
        mintAmount > 1
            ? "WORTHLESSS pieces of sh!t™"
            : "WORTHLESSS piece of sh!t™";

    return (
        <Dialog
            className="DialogSubscribe"
            isOpen={open}
            onClose={handleOnClose}
        >
            <div className="body">
                <Label className="supertitle" color="var(--color-green)">
                    CONGRATS
                    <br /> AND THANK YOU!
                </Label>
                <Label type="body" className="label">
                    You're now the proud owner of {mintNumber} new{" "}
                    <b>
                        <i>{mintText}</i>
                    </b>
                    . Visit{" "}
                    <a href="https://opensea.io/collection/worthlesss-pieces-of-shit">
                        OpenSea
                    </a>{" "}
                    to view your totally amazing WPOS™!
                    <br />
                    <br /> We thank you from the bottom of our hearts for
                    putting faith in our{" "}
                    <b>
                        <i>WORTHLESSS</i>
                    </b>{" "}
                    community.
                    <br />
                    <br /> This is just the beginning! Please stay active and
                    join the Discord if you haven't already to receive updates
                    on the community, the treasury, and the roadmap.
                </Label>
            </div>
            <div className="column buttons">
                <DiscordButton />
                <div className="row">
                    <InstagramButton />
                    <TwitterButton />
                </div>
            </div>
        </Dialog>
    );
};

export default SuccessDialog;
