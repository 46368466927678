// Imports

import { RiArrowRightCircleFill } from "react-icons/ri";
import React, { useState, useEffect } from "react";

import "./MailchimpStyles.css";
import Label from "../Label";

// Mailchimp Form

const MailchimpForm = ({ status, onValidated }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
      });
  };

  useEffect(() => {
    if (status === "success") clearFields();
  }, [status]);

  const clearFields = () => {
    setEmail("");
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="Subscribe">
        {status !== "success" && (
          <Label type="body" color="var(--color-white)">
            Get important{" "}
            <b>
              <i>WORTHLESSS</i>
            </b>{" "}
            updates
          </Label>
        )}
        {status === "success" && (
          <Label type="body" color="var(--color-white)">
            You are now subcribed to{" "}
            <b>
              <i>WORTHLESSS</i>
            </b>{" "}
          </Label>
        )}
        <div className="row">
          <input
            type="email"
            value={email}
            placeholder="Your best email"
            className="input-email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <button className="button-submit">
            <RiArrowRightCircleFill size={28} />
          </button>
        </div>
      </div>
    </form>
  );
};

export default MailchimpForm;
