// Imports

import { RiInstagramFill } from "react-icons/ri";

import { logButtonPressed } from "../../../clients/firebase";

// Instagram Button

const InstagramButton = (e) => {
  const handleOnClick = (e) => {
    logButtonPressed("follow_our_insta");
    window.open("https://www.instagram.com/worthlesssclub");
  };

  return (
    <button className="SocialButton instagram" onClick={handleOnClick}>
      Follow our Insta
      <RiInstagramFill size={18} />
    </button>
  );
};

export default InstagramButton;
