// Imports

import IMAGE_1 from '../../assets/png/1.png'
import IMAGE_2 from '../../assets/png/2.png'
import IMAGE_3 from '../../assets/png/3.png'
import IMAGE_4 from '../../assets/png/4.png'

// FourByFour

const FourByFour = ({ className, margin, imgOne, imgTwo, imgThree, imgFour }) => {
    let styles = {
        margin
    }
    return (
        <div className={`FourByFour ${className}`} style={styles}>
            <img className="square one" src={imgOne}  alt="one"/>
            <img className="square two" src={imgTwo}  alt="two"/>
            <img className="square three" src={imgThree}  alt="three"/>
            <img className="square four" src={imgFour} alt="four"/>
        </div>
    )
}

FourByFour.defaultProps = {
    imgOne: IMAGE_1,
    imgTwo: IMAGE_2,
    imgThree: IMAGE_3,
    imgFour: IMAGE_4,
}

export default FourByFour