// TeamMembers

import Label from "../Label";

const TeamMembers = ({ margin }) => {
    let styles = {
        margin,
    };

    return (
        <div className="TeamMembers column" style={styles}>
            <Label type="body">
                <span className="name">Peanut Butter Man</span> (tech) A crypto
                investor since 2012. Peanut Butter Man is a Web 2 programmer by
                day and Web 3 builder by night.
            </Label>
            <Label type="body">
                <span className="name">Ham</span> (community) A long-time BTC
                bag holder, Ham is a true blockchain believer. An actor by
                trade, he has starred in films, commercials and everything in
                between.
            </Label>
            <Label type="body">
                <span className="name">Catsomatic</span> (art) A polite Canadian
                who's just as dangerous with a pen as he is with drumsticks (the
                ones made of wood, not chicken).
            </Label>
            <Label type="body">
                <span className="name">BDawg</span> (growth) A man of mystery.
                Bdawg is the first guy in the gym and the last to leave.
            </Label>
        </div>
    );
};

export default TeamMembers;
