// Imports

import React from "react";

// Label

const Label = ({
  className,
  flexBasis,
  children,
  content,
  type,
  color,
  margin,
}) => {
  let result;
  let styles = {
    color,
    margin,
    flexBasis,
  };
  switch (type) {
    case "supertitle": {
      result = (
        <h1 className={`label supertitle ${className}`} style={styles}>
          {content}
          {children}
        </h1>
      );
      break;
    }
    case "title": {
      result = (
        <h2 className={`label title ${className}`} style={styles}>
          {content}
          {children}
        </h2>
      );
      break;
    }
    case "subtitle": {
      result = (
        <h3 className={`label subtitle ${className}`} style={styles}>
          {content}
          {children}
        </h3>
      );
      break;
    }
    case "exclamation": {
      result = (
        <h4 className={`label exclamation ${className}`} style={styles}>
          {content}
          {children}
        </h4>
      );
      break;
    }
    case "subtext": {
      result = (
        <h5 className={`label subtext ${className}`} style={styles}>
          {content}
          {children}
        </h5>
      );
      break;
    }
    case "legal": {
      result = (
        <p className={`label legal-text ${className}`} style={styles}>
          {content}
          {children}
        </p>
      );
      break;
    }
    case "body": {
      result = (
        <p className={`label body-text ${className}`} style={styles}>
          {content}
          {children}
        </p>
      );
      break;
    }
    case "small": {
      result = (
        <p className={`label small-text ${className}`} style={styles}>
          {content}
          {children}
        </p>
      );
      break;
    }
    case "tiny": {
      result = (
        <p className={`label tiny-text ${className}`} style={styles}>
          {content}
          {children}
        </p>
      );
      break;
    }
    default:
      return <h1>{content}</h1>;
  }
  return result;
};

Label.defaultProps = {
  type: "title",
};

export default Label;
