// Imports

import RANDOM_GIF from "../../assets/gif/random.gif";

// OneByOne

const OneByOne = ({ margin }) => {
  const styles = {
    margin,
  };
  return (
    <div className="OneByOne" style={styles}>
      <img className="square one" src={RANDOM_GIF} alt="one" />
    </div>
  );
};

export default OneByOne;
