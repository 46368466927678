// Imports

import { RiDiscordFill } from "react-icons/ri";

import { logButtonPressed } from "../../../clients/firebase";

// Discord Button

const DiscordButton = () => {
    const handleOnClick = (e) => {
        logButtonPressed("join_our_discord");
        window.open("https://discord.gg/dysVAagmS8");
    };

    return (
        <button className="SocialButton discord" onClick={handleOnClick}>
            Join our Discord
            <RiDiscordFill size={20} />
        </button>
    );
};

export default DiscordButton;
