// Imports

import React from 'react'

import Label from '../Label'

// Strip

const Strip = ({ text, children, className }) => {
    return (
        <div className={`Strip ${className}`}>
             <Label type="exclamation">
                 {text}
                 {children}
            </Label>
        </div>
    )
}

export default Strip