// Imports

import { RiTwitterFill, RiDiscordFill, RiInstagramFill } from "react-icons/ri";
import React from "react";

// Content

const Content = ({ className, children }) => {
    return (
        <div className={`Content ${className}`}>
            <div className="inner-container column">{children}</div>
        </div>
    );
};

export default Content;
