// Imports

import { useState, useEffect } from "react";

import {
    connectWallet,
    getCurrentWalletConnected,
    addWalletListener,
} from "../../../utils/interact";
import Strip from "../../Strip";

// Mint Button

const MintButton = ({ onToast, onMint, onSetWalletAddress, quantity }) => {
    let connectedClassName = "";

    const [walletAddress, setWalletAddress] = useState(null);
    const [loading, setLoading] = useState(false);
    const [prevStatus, setPrevStatus] = useState(null);
    const [status, setStatus] = useState(null);
    const [type, setType] = useState("info");

    const handleOnClick = async (e) => {
        setLoading(true);
        if (!walletAddress) {
            const walletResponse = await connectWallet();
            setType(walletResponse.type);
            setStatus(walletResponse.status);
            setWalletAddress(walletResponse.address);
            onSetWalletAddress(walletResponse.address);
        } else {
            if (typeof onMint === "function") {
                onMint(e);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        if (walletAddress) {
            if (typeof onSetWalletAddress === "function") {
                onSetWalletAddress(walletAddress);
            }
        }
    }, [walletAddress, onSetWalletAddress]);

    useEffect(() => {
        if (status !== prevStatus && typeof onToast === "function") {
            setPrevStatus(status);
            onToast(status, type);
        }
    }, [status, prevStatus, onToast]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        const { address } = await getCurrentWalletConnected();
        setWalletAddress(address);
        addWalletListener(setWalletAddress, setStatus, setType);
    }, []);

    if (walletAddress) {
        connectedClassName = "connected";
    }

    const mintDetails =
        quantity > 1
            ? `WORTHLESSS pieces of sh!t™`
            : `WORTHLESSS piece of sh!t™`;

    return (
        <div className="Minter">
            <button
                id="mintButton"
                className="MintButton"
                onClick={(e) => handleOnClick(e)}
            >
                {!loading && walletAddress && (
                    <>
                        <i>MINT</i>
                        <p>
                            {" "}
                            {quantity}{" "}
                            <b>
                                <i>{mintDetails}</i>
                            </b>{" "}
                        </p>
                    </>
                )}
                {!loading && !walletAddress && (
                    <>
                        <i>CONNECT</i>
                        <p>
                            <b>
                                <i>
                                    HINT: MAKE SURE YOU'RE ON THE ETHEREUM
                                    MAINNET
                                </i>
                            </b>{" "}
                        </p>
                    </>
                )}
                {loading && (
                    <>
                        <div class="loader" />
                    </>
                )}
            </button>
            <Strip className={`walletAddress ${connectedClassName}`}>
                {!walletAddress && (
                    <>
                        Your wallet is not connected. Tap the button above to
                        connect.
                    </>
                )}
                {walletAddress && <>Connected: {walletAddress}</>}
            </Strip>
        </div>
    );
};

export default MintButton;
