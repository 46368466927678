// Imports

import { useHistory } from "react-router";

// Mint Link Button

const MintLinkButton = () => {
    const history = useHistory();

    const handleOnClick = async (e) => {
        history.push("./mint");
    };

    return (
        <div className="Minter">
            <button
                id="mintButton"
                className="MintButton"
                onClick={(e) => handleOnClick(e)}
            >
                <>
                    <i>MINT</i>
                    <p>
                        <b>
                            <i>WORTHLESSS pieces of sh!t™ </i>
                        </b>{" "}
                    </p>
                </>
            </button>
        </div>
    );
};

export default MintLinkButton;
