// Imports

import { RiTwitterFill } from "react-icons/ri";

import { logButtonPressed } from "../../../clients/firebase";

// Twitter Button

const TwitterButton = () => {
  const handleOnClick = (e) => {
    logButtonPressed("follow_our_twitter");
    window.open("https://www.twitter.com/worthlesssclub");
  };

  return (
    <button className="SocialButton twitter" onClick={handleOnClick}>
      Follow our Twitter
      <RiTwitterFill size={18} />
    </button>
  );
};

export default TwitterButton;
