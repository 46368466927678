// Imports

import Content from "../../containers/Content";
import Section from "../../containers/Section";
import Footer from "../../containers/Footer";
import Header from "../../containers/Header";
import Label from "../../components/Label";
import Strip from "../../components/Strip";

// Terms

const Terms = () => {
    return (
        <>
            <Header />
            <Strip text="Warning! Don't be fooled by scams. We are the only authentic seller of WORTHLESSS pieces of sh!t™" />
            <Content>
                <Section
                    className="legal"
                    padding="var(--spacing-16x) var(--spacing-8x)"
                >
                    <div className="row">
                        <div className="column">
                            <Label
                                color="var(--color-white)"
                                content={"Terms & Conditions"}
                                margin="0"
                            />
                            <Label type="legal" color="var(--color-white)">
                                <b>
                                    <i>WORTHLESSS pieces of sh!t™ </i>
                                </b>{" "}
                                is a collection of digital artworks (NFTs)
                                running on the Ethereum network. This website is
                                only an interface allowing participants to
                                exchange digital collectibles. Users are
                                entirely responsible for the safety and
                                management of their own private Ethereum wallets
                                and validating all transactions and contracts
                                generated by this website before approval.
                                Furthermore, as the{" "}
                                <b>
                                    <i>WORTHLESSS pieces of sh!t™ </i>
                                </b>{" "}
                                smart contract runs on the Ethereum network,
                                there is no ability to undo, reverse, or restore
                                any transactions.
                                <br />
                                <br />
                                This website and its connected services are
                                provided “as is” and “as available” without
                                warranty of any kind. By using this website you
                                are accepting sole responsibility for any and
                                all transactions involving
                                <b>
                                    <i> WORTHLESSS pieces of sh!t™ </i>
                                </b>{" "}
                                digital collectibles.
                            </Label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column">
                            <Label
                                color="var(--color-white)"
                                content={"Ownership"}
                                margin="var(--spacing-8x) 0 0"
                            />
                            <Label type="legal" color="var(--color-white)">
                                i. You Own the NFT.{" "}
                                <b>
                                    <i>WORTHLESSS pieces of sh!t™ </i>
                                </b>
                                is an NFT on the Ethereum blockchain. When you
                                purchase an NFT, you own the underlying{" "}
                                <b>
                                    <i>WORTHLESSS piece of sh!t™ </i>
                                </b>
                                , the Art, completely. Ownership of the NFT is
                                mediated entirely by the Smart Contract and the
                                Ethereum Network: at no point may we seize,
                                freeze, or otherwise modify the ownership of any{" "}
                                <b>
                                    <i>WORTHLESSS piece of sh!t™ </i>
                                </b>
                                .<br />
                                <br />
                                ii. Personal Use. Subject to your continued
                                compliance with these Terms, Afterthought LLC
                                grants you a worldwide, royalty-free license to
                                use, copy, and display the purchased Art, along
                                with any extensions that you choose to create or
                                use, solely forthe following purposes: (i) for
                                your own personal, non-commercial use; (ii) as
                                part of a marketplace that permits the purchase
                                and sale of your{" "}
                                <b>
                                    <i>WORTHLESSS piece of sh!t™ </i>
                                </b>{" "}
                                / NFT, provided that the marketplace
                                cryptographically verifies each{" "}
                                <b>
                                    <i>WORTHLESSS piece of sh!t™ </i>
                                </b>{" "}
                                owner’s rights to display the Art for their{" "}
                                <b>
                                    <i>WORTHLESSS piece of sh!t™ </i>
                                </b>{" "}
                                to ensure that only the actual owner can display
                                the Art; or (iii) as part of a third party
                                website or application that permits the
                                inclusion, involvement, or participation of your{" "}
                                <b>
                                    <i>WORTHLESSS piece of sh!t™ </i>
                                </b>
                                , provided that the website/application
                                cryptographically verifies each{" "}
                                <b>
                                    <i>WORTHLESSS piece of sh!t™ </i>
                                </b>{" "}
                                owner’s rights to display the Art for their{" "}
                                <b>
                                    <i>WORTHLESSS piece of sh!t™ </i>
                                </b>
                                to ensure that only the actual owner can display
                                the Art, and provided that the Art is no longer
                                visible once the owner of the{" "}
                                <b>
                                    <i>WORTHLESSS piece of sh!t™ </i>
                                </b>{" "}
                                leaves the website/application.
                                <br />
                                <br />
                                iii. Commercial Use. Subject to your continued
                                compliance with these Terms, Afterthought LLC
                                grants you an unlimited, worldwide license to
                                use, copy, and display the purchased Art for the
                                purpose of creating derivative works based upon
                                the Art (“Commercial Use”). Examples of such
                                Commercial Use would e.g. be the use of the Art
                                to produce and sell merchandise products
                                (T-Shirts etc.) displaying copies of the Art.
                                For the sake of clarity, nothing in this Section
                                will be deemed to restrict you from (i) owning
                                or operating a marketplace that permits the use
                                and sale of{" "}
                                <b>
                                    <i>WORTHLESSS pieces of sh!t™ </i>
                                </b>{" "}
                                generally, provided that the marketplace
                                cryptographically verifies each{" "}
                                <b>
                                    <i>WORTHLESSS piece of sh!t™ </i>
                                </b>{" "}
                                owner’s rights to display the Art for their{" "}
                                <b>
                                    <i>WORTHLESSS piece of sh!t™ </i>
                                </b>
                                to ensure that only the actual owner can display
                                the Art; (ii) owning or operating a third party
                                website or application that permits the
                                inclusion, involvement, or participation of{" "}
                                <b>
                                    <i>WORTHLESSS pieces of sh!t™ </i>
                                </b>{" "}
                                generally, provided that the third party website
                                or application cryptographically verifies each{" "}
                                <b>
                                    <i>WORTHLESSS piece of sh!t™ </i>
                                </b>{" "}
                                owner’s rights to display the Art for their{" "}
                                <b>
                                    <i>WORTHLESSS pieces of sh!t™ </i>
                                </b>{" "}
                                to ensure that only the actual owner can display
                                the Art, and provided that the Art is no longer
                                visible once the owner of the Purchased{" "}
                                <b>
                                    <i>WORTHLESSS piece of sh!t™ </i>
                                </b>{" "}
                                leaves the website/application; or (iii) earning
                                revenue from any of the foregoing.
                            </Label>
                        </div>
                    </div>
                </Section>
            </Content>
            <Footer />
            <Strip text="©2021 A WORTHLESSS PRODUCT" />
        </>
    );
};

export default Terms;
