// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "worthlesss.firebaseapp.com",
  projectId: "worthlesss",
  storageBucket: "worthlesss.appspot.com",
  messagingSenderId: "1045579080115",
  appId: "1:1045579080115:web:bddb379b4d7f27cf6e2bc6",
  measurementId: "G-CH1PN1Z95N",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Analytics

const logButtonPressed = (buttonName) => {
  logEvent(analytics, "button_pressed", {
    name: buttonName,
  });
};

const logLinkPressed = (link) => {
  logEvent(analytics, "link_pressed", {
    name: link,
  });
};

export { app, analytics, logButtonPressed, logLinkPressed };
