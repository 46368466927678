// Imports

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Provenance from "./modules/Provenance";
import Terms from "./modules/Terms";
import Mint from "./modules/Mint";
import Home from "./modules/Home";
import "./App.css";

// App

function App() {
    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route path="/provenance" component={Provenance} />
                    <Route path="/terms" component={Terms} />
                    <Route path="/mint" component={Mint} />
                    <Route path="/" component={Home} />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
