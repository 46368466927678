// Imports

import styled from "styled-components";
import React from "react";

// Section

const Section = ({ className, children, margin, marginBottom, padding }) => {
    let styles = {
        margin,
        padding,
        marginBottom,
    };
    return (
        <section className={`Section ${className}`} style={styles}>
            <div className="inner-container column">{children}</div>
        </section>
    );
};

export default Section;
