// Imports

import MailchimpSubscribe from "react-mailchimp-subscribe";
import React from "react";

import MailchimpForm from "./Form";
import "./MailchimpStyles.css";

// Mailchimp Form Container

const MailchimpFormContainer = ({ onRequestDialogOpen }) => {
  const postUrl = `https://worthlesss.us5.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

  const handleRequestDialogOpen = (e) => {
    e.preventDefault();
    onRequestDialogOpen();
  };

  return (
    <div className="mc__form-container" onClick={handleRequestDialogOpen}>
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <MailchimpForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

export default MailchimpFormContainer;
