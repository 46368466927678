// Imports

import store from "../store";

// Actions

const fetchDataRequest = () => {
    return {
        type: "CHECK_DATA_REQUEST",
    };
};

const fetchDataSuccess = (payload) => {
    return {
        type: "CHECK_DATA_SUCCESS",
        payload: payload,
    };
};

const fetchDataFailed = (payload) => {
    return {
        type: "CHECK_DATA_FAILED",
        payload: payload,
    };
};

export const fetchData = () => {
    return async (dispatch) => {
        dispatch(fetchDataRequest());
        try {
            const totalSupply = await store
                .getState()
                .blockchain.smartContract.methods.totalSupply()
                .call();
            dispatch(
                fetchDataSuccess({
                    totalSupply,
                })
            );
            console.log("TOTAL SUPPLY", totalSupply);
        } catch (err) {
            console.log(err);
            dispatch(fetchDataFailed("Could not load data from contract."));
        }
    };
};
