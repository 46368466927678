// Imports

// Utils

export const connectWallet = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            const obj = {
                status: "Your wallet is connected!",
                address: addressArray[0],
                type: "success",
            };
            return obj;
        } catch (err) {
            return {
                address: "",
                status: "Oops! There was a mistake. Please try again.",
                type: "error",
            };
        }
    } else {
        return {
            address: "",
            type: "info",
            status: (
                <span>
                    <p>
                        {" "}
                        🦊{" "}
                        <a
                            className="link-metamask"
                            target="_blank"
                            rel="noreferrer"
                            href={`https://metamask.io/download.html`}
                        >
                            You need to install Metamask first.
                        </a>
                    </p>
                </span>
            ),
        };
    }
};

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_accounts",
            });
            if (addressArray.length > 0) {
                return {
                    address: addressArray[0],
                    status: "Your wallet is connected!",
                    type: "success",
                };
            } else {
                return {
                    address: "",
                    status: "🦊 Please connect to Metamask first.",
                    type: "info",
                };
            }
        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message,
                type: "error",
            };
        }
    } else {
        return {
            address: "",
            type: "info",
            status: (
                <span>
                    <p>
                        {" "}
                        🦊{" "}
                        <a
                            className="link-metamask"
                            target="_blank"
                            rel="noreferrer"
                            href={`https://metamask.io/download.html`}
                        >
                            You need to install Metamask first.
                        </a>
                    </p>
                </span>
            ),
        };
    }
};

export const addWalletListener = (
    handleSetWallet,
    handleSetStatus,
    handleSetType
) => {
    if (window.ethereum) {
        window.ethereum.on("accountsChanged", (accounts) => {
            if (accounts.length > 0) {
                handleSetWallet(accounts[0]);
                handleSetType("success");
                handleSetStatus("Your wallet is connected!");
            } else {
                handleSetWallet("");
                handleSetType("info");
                handleSetStatus("🦊 Please connect to Metamask.");
            }
        });
    } else {
        handleSetType("info");
        handleSetStatus(
            <p>
                {" "}
                🦊{" "}
                <a
                    className="link-metamask"
                    target="_blank"
                    rel="noreferrer"
                    href={`https://metamask.io/download.html`}
                >
                    You must install Metamask.
                </a>
            </p>
        );
    }
};
