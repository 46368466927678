// Imports

import BANNER from '../../assets/png/worthlesss.jpg'

// Banner

const Banner = () => {

    return (
        <div className="Banner">
            <img className="image" src={BANNER}  alt=""/>
        </div>
    )
}

export default Banner