// Imports

import React, { useState, useRef, useEffect } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { Spinner } from "@blueprintjs/core";

import { logButtonPressed } from "../../clients/firebase";

// Mailer

const Mailer = ({ onSubscribe }) => {
  const postUrl = `https://worthlesss.us5.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const inputRef = useRef();

  const handleSubmit = (e, subscribe) => {
    e.preventDefault();
    logButtonPressed("subscribe");
    setLoading(true);
    email &&
      email.indexOf("@") > -1 &&
      subscribe({
        EMAIL: email,
      });
    setLoading(false);
  };

  const clearFields = () => {
    setEmail("");
  };

  useEffect(() => {
    if (inputRef && inputRef.current) {
      let aTimeout = setTimeout(() => {
        inputRef.current.focus();
      }, 1000);
      return () => {
        clearTimeout(aTimeout);
      };
    }
  }, []);

  return (
    <div className="mc__form-container">
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => {
          if (status === "success") {
            clearFields();
            localStorage.setItem("subscribed", "true");
            onSubscribe();
          }
          return (
            <form onSubmit={(e) => handleSubmit(e, subscribe)}>
              <div className="Subscribe">
                {status === "success" && (
                  <div className="column success">
                    <RiCheckboxCircleFill size={140} />
                  </div>
                )}
                {status !== "success" && (
                  <div className="column">
                    <input
                      ref={inputRef}
                      type="email"
                      value={email}
                      placeholder="Your best email"
                      className="input-subscribe"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button
                      disabled={loading}
                      type="submit"
                      className="button-subscribe"
                    >
                      {!loading && <>SUBMIT</>}
                      {loading && <Spinner size={20} />}
                    </button>
                  </div>
                )}
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

export default Mailer;
