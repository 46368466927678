// Imports

import { RiTwitterFill, RiDiscordFill, RiInstagramFill } from "react-icons/ri";
import { useHistory, useLocation } from "react-router";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { logButtonPressed, logLinkPressed } from "../../clients/firebase";

// Sub Components

const NavContainer = styled.nav`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

const Title = styled.h1`
    color: var(--color-white);
    font-size: var(--font-size-header-1);
    font-weight: 900;
    &:hover {
        color: var(--color-green);
    }
`;

// Header

const Header = ({ buyRef, teamRef, isSaleActive }) => {
    const history = useHistory();
    const location = useLocation();
    const [buyButtonRef, setBuyButtonRef] = useState(buyRef);
    const [teamButtonRef, setTeamButtonRef] = useState(teamRef);

    useEffect(() => {
        setBuyButtonRef(buyRef);
    }, [buyRef]);

    useEffect(() => {
        setTeamButtonRef(teamRef);
    }, [teamRef]);

    const handleSocialOnClick = (e, social) => {
        switch (social) {
            case "twitter": {
                logLinkPressed("twitter");
                window.open("https://www.twitter.com/worthlesssclub/");
                break;
            }
            case "discord": {
                logLinkPressed("discord");
                window.open("https://discord.gg/dysVAagmS8");
                break;
            }
            case "instagram": {
                logLinkPressed("instagram");
                window.open("https://www.instagram.com/worthlesssclub/");
                break;
            }
            default:
                break;
        }
    };

    return (
        <NavContainer className="Header">
            <div className="inner-container row">
                <div>
                    <Link to="/" className="logo">
                        <Title>
                            <i>WORTHLESSS</i>
                        </Title>
                    </Link>
                </div>
                <ul className="list">
                    <li
                        onClick={(e) => {
                            if (isSaleActive) {
                                if (location.pathname !== "/mint") {
                                    history.push("/mint");
                                }
                            } else {
                                if (location.pathname !== "/mint") {
                                    history.push("/mint");
                                } else {
                                    buyButtonRef?.current?.scrollIntoView({
                                        behavior: "smooth",
                                    });
                                }
                            }
                            logButtonPressed("buy_wpos");
                        }}
                        className="list-item"
                    >
                        Buy a <i> WPOS™</i>
                    </li>
                    <li
                        onClick={(e) => {
                            if (location.pathname === "/") {
                                teamButtonRef?.current?.scrollIntoView({
                                    behavior: "smooth",
                                });
                            } else {
                                history.push("/");
                            }
                            logButtonPressed("team");
                        }}
                        className="list-item"
                    >
                        Team
                    </li>
                    <li
                        onClick={(e) => {
                            logButtonPressed("docs");
                            window.location = "https://docsss.worthlesss.com";
                        }}
                        className="list-item"
                    >
                        Docs
                    </li>
                    <li
                        className="list-item icon"
                        onClick={(e) => handleSocialOnClick(e, "twitter")}
                    >
                        <RiTwitterFill size={20} />
                    </li>
                    <li
                        className="list-item icon"
                        onClick={(e) => handleSocialOnClick(e, "discord")}
                    >
                        <RiDiscordFill size={20} />
                    </li>
                    <li
                        className="list-item icon"
                        onClick={(e) => handleSocialOnClick(e, "instagram")}
                    >
                        <RiInstagramFill size={20} />
                    </li>
                </ul>
            </div>
        </NavContainer>
    );
};

export default Header;
