// Imports

import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import { connect } from "../../redux/blockchain/blockchainActions";
import SuccessDialog from "../../components/SuccessDialog";
import { fetchData } from "../../redux/data/dataActions";
import DiscordStrip from "../../components/DiscordStrip";
import MintButton from "../../components/buttons/Mint";
import Content from "../../containers/Content";
import Section from "../../containers/Section";
import Footer from "../../containers/Footer";
import Header from "../../containers/Header";
import Label from "../../components/Label";
import Strip from "../../components/Strip";

import { analytics, logButtonPressed } from "../../clients/firebase";

// Mint

const Mint = () => {
    const dispatch = useDispatch();

    const data = useSelector((state) => state.data);
    const blockchain = useSelector((state) => state.blockchain);
    const [walletAddress, setWalletAddress] = useState(null);

    const [isOpenSuccessDialog, setIsOpenSuccessDialog] = useState(false);

    const [claimingNFT, setClaimingNFT] = useState(false);
    const [prevMsg, setPrevMsg] = useState(null);
    const [mintAmount, setMintAmount] = useState(1);

    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        IS_PRESALE: false,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: "",
        SHOW_BACKGROUND: false,
    });

    const incrementMintAmount = () => {
        let newMintAmount = mintAmount + 1;
        if (newMintAmount > 20) {
            newMintAmount = 20;
        }
        setMintAmount(newMintAmount);
    };

    const decrementMintAmount = () => {
        let newMintAmount = mintAmount - 1;
        if (newMintAmount < 1) {
            newMintAmount = 1;
        }
        setMintAmount(newMintAmount);
    };

    const handleOnToast = (msg, type) => {
        if (msg !== prevMsg && msg !== null) {
            setPrevMsg(prevMsg);
            toast[type](msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const mint = () => {
        let cost = CONFIG.IS_PRESALE
            ? CONFIG.WEI_COST_PRESALE
            : CONFIG.WEI_COST;
        let gasLimit = CONFIG.GAS_LIMIT;
        let totalCostWei = String(cost * mintAmount);
        let totalGasLimit = String(gasLimit * mintAmount);
        console.log("Cost: ", totalCostWei);
        console.log("Gas limit: ", totalGasLimit);
        handleOnToast(`Confirm your transaction via Metamask...`, "info");
        setClaimingNFT(true);
        blockchain.smartContract.methods
            .mint(mintAmount)
            .send({
                gasLimit: String(totalGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
            .once("error", (err) => {
                console.log(err);
                handleOnToast(
                    "Sorry, something went wrong or you rejected your transaction. Please try again.",
                    "error"
                );
                setClaimingNFT(false);
            })
            .then((receipt) => {
                console.log(receipt);
                handleOnToast(
                    `Success! You're the proud of owner of ${mintAmount} new WPOS™!`,
                    "success"
                );
                setClaimingNFT(false);
                setIsOpenSuccessDialog(true);
                dispatch(fetchData(blockchain.account));
            });
    };

    const handleOnMint = (e) => {
        e.preventDefault();
        logButtonPressed("mint");
        mint();
    };

    const getData = () => {
        console.log(blockchain);
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    const getConfig = async () => {
        const configResponse = await fetch("/config/config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    useEffect(() => {
        getConfig();
    }, []);

    useEffect(() => {
        if (walletAddress) {
            dispatch(connect());
            getData();
        }
    }, [walletAddress]);

    useEffect(() => {
        getData();
    }, [blockchain.account]);

    window.scroll(0, 0);

    const mintNumber = mintAmount > 1 ? mintAmount : "";
    const mintText =
        mintAmount > 1
            ? "WORTHLESSS pieces of sh!t™."
            : "WORTHLESSS piece of sh!t™.";

    console.log(data.totalSupply);

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Header />
            <DiscordStrip
                text="Click here to join 1620+ others in our Discord!"
                className="discord"
            />
            {claimingNFT && (
                <Content className="ClaimingContent">
                    <Section
                        className="Minting"
                        padding="var(--spacing-16x) var(--spacing-4x)"
                    >
                        <Label className="supertitle read-this" margin="0">
                            READ THIS
                        </Label>
                        <Label
                            className="supertitle minting-prompt"
                            color="var(--color-white)"
                            margin="0"
                        >
                            1) DO NOT CLOSE METAMASK WITHOUT CONFIRMING OR
                            REJECTING YOUR TRANSACTION.
                            <br />
                            <br />
                            2) PLEASE REVIEW YOUR TRANSACTION AMOUNT AND CONFIRM
                            ON METAMASK.
                            <br />
                            <br />
                            3) AFTER CONFIRMING, PLEASE CONTINUE TO WAIT (UP TO
                            A FEW MINUTES) WHILE WE PROCESS YOUR TRANSACTION.
                            <br />
                            <br />
                            <Label type="small" className="MintWarning">
                                Please do not press the back button or refresh
                                this page while minting. If you do, even though
                                your transaction may succeed, you will not be
                                shown confirmation here. You will have to visit
                                OpenSea or open your Metamask to ensure your
                                transaction was successful.
                            </Label>
                        </Label>
                        <>
                            <div class="big-loader" />
                        </>
                    </Section>
                    <Section className="MintNotes">
                        <Label
                            type="body"
                            color="var(--color-grey-dark)"
                            className="specs"
                        >
                            <i>
                                1) Please make sure you are connected to the
                                right network (Ethereum Mainnet). Please note:
                                once you confirm your transaction, you cannot
                                undo this action.
                                <br />
                                <br />
                                2) We have set the gas limit to 285000 for the
                                contract to successfully mint your NFT. We
                                recommend that you don't lower the gas limit or
                                your transaction may not succeed.
                                <br />
                                <br />
                                Happy minting!
                                <br />
                                The{" "}
                                <b>
                                    <i>WORTHLESSS</i>
                                </b>{" "}
                                team
                            </i>
                        </Label>
                        <Label
                            type="body"
                            color="var(--color-white)"
                            className="ContractAddress"
                        >
                            <a href={CONFIG.SCAN_LINK} target="_blank">
                                <i>
                                    <b>
                                        CONTRACT ADDRESS:{" "}
                                        {CONFIG.CONTRACT_ADDRESS}
                                    </b>
                                </i>
                            </a>
                        </Label>
                    </Section>
                </Content>
            )}
            {!claimingNFT && parseInt(data.totalSupply) === 10002 && (
                <Content className="SoldOut">
                    <Section
                        className="Minting"
                        padding="var(--spacing-16x) var(--spacing-4x)"
                    >
                        <Label
                            className="sold-out"
                            color="var(--color-white)"
                            margin="0"
                        >
                            SOLD OUT!!!
                        </Label>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://opensea.io/collection/worthlesss-pieces-of-shit"
                        >
                            <Label
                                className="sold-out-open-sea"
                                color="var(--color-grey-dark)"
                                margin="0"
                            >
                                View on OpenSea
                            </Label>
                        </a>
                    </Section>
                </Content>
            )}
            {!claimingNFT && parseInt(data.totalSupply) !== 10002 && (
                <Content className="NotClaimingContent">
                    <Section
                        className="Mint"
                        padding="var(--spacing-16x) var(--spacing-4x)"
                    >
                        <Label className="sale-status">SALE IS LIVE!</Label>
                        <Label className="sale-directions">
                            USE THE + and - BUTTONS TO SELECT AN AMOUNT (MAX OF
                            20 PER TRANSACTION) AND THEN HIT THE MINT BUTTON
                            BELOW TO CLAIM YOUR VERY OWN WPOS™.
                        </Label>
                        <div className="MintAmount row" width="100%">
                            <button
                                className="MintAmountButton"
                                onClick={decrementMintAmount}
                            >
                                -
                            </button>
                            <Label
                                className="mint-amount"
                                margin="0 var(--spacing-10x) 0 var(--spacing-10x) "
                            >
                                {mintAmount}
                            </Label>
                            <button
                                className="MintAmountButton"
                                onClick={incrementMintAmount}
                            >
                                +
                            </button>
                        </div>
                        {data && parseInt(data.totalSupply) !== 0 && (
                            <div className="MintSupply row" width="100%">
                                {data.totalSupply} minted out of 10,002
                            </div>
                        )}
                        <MintButton
                            quantity={mintAmount}
                            onToast={handleOnToast}
                            onMint={handleOnMint}
                            onSetWalletAddress={setWalletAddress}
                        />
                        <Label type="small" className="MintPricing">
                            Each{" "}
                            <b>
                                <i>WPOS™</i>
                            </b>{" "}
                            costs{" "}
                            {CONFIG?.IS_PRESALE
                                ? CONFIG.DISPLAY_COST_PRESALE
                                : CONFIG.DISPLAY_COST}{" "}
                            ETH (exluding gas fees).
                        </Label>
                        {blockchain.errorMsg && (
                            <Strip className="ethNetwork">
                                <>
                                    OOPS! Please open Metamask and switch to the
                                    Ethereum Mainnet.
                                </>
                            </Strip>
                        )}
                    </Section>
                    <Section className="MintNotes">
                        <Label
                            type="body"
                            color="var(--color-grey-dark)"
                            className="specs"
                        >
                            <i>
                                1) Please make sure you are connected to the
                                right network (Ethereum Mainnet). Please note:
                                once you confirm your transaction, you cannot
                                undo this action.
                                <br />
                                <br />
                                2) We have set the gas limit to 285000 for the
                                contract to successfully mint your NFT. We
                                recommend that you don't lower the gas limit or
                                your transaction may not succeed.
                                <br />
                                <br />
                                Happy minting!
                                <br />
                                The{" "}
                                <b>
                                    <i>WORTHLESSS</i>
                                </b>{" "}
                                team
                            </i>
                        </Label>
                        <Label
                            type="body"
                            color="var(--color-white)"
                            className="ContractAddress"
                        >
                            <a href={CONFIG.SCAN_LINK} target="_blank">
                                <i>
                                    <b>
                                        CONTRACT ADDRESS:{" "}
                                        {CONFIG.CONTRACT_ADDRESS}
                                    </b>
                                </i>
                            </a>
                        </Label>
                        <Label type="body" color="var(--color-white)">
                            If you are having trouble minting, please join us in
                            our{" "}
                            <a
                                className="social-link"
                                href="https://discord.gg/worthlesss"
                                target="_blank"
                            >
                                Discord
                            </a>{" "}
                            or DM us on{" "}
                            <a
                                className="social-link"
                                href="https://twitter.com/worthlesssclub"
                                target="_blank"
                            >
                                Twitter
                            </a>{" "}
                            and someone will happily assit you.
                        </Label>
                    </Section>
                </Content>
            )}
            <Footer />
            <Strip text="©2021 A WORTHLESSS PRODUCT" />
            <SuccessDialog
                mintAmount={mintAmount}
                open={isOpenSuccessDialog}
                onClose={(e) => setIsOpenSuccessDialog(false)}
            />
        </>
    );
};

export default Mint;
