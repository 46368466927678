// Imports

import { ToastContainer, toast } from "react-toastify";
import { useRef, useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";

import DroppingSoonButton from "../../components/buttons/DroppingSoon";
import MintLinkButton from "../../components/buttons/MintLink";
import SubscribeDialog from "../../components/SubscribeDialog";
import DiscordStrip from "../../components/DiscordStrip";
import TeamMembers from "../../components/TeamMembers";
import FourByFour from "../../components/FourByFour";
import OneByOne from "../../components/OneByOne";
import Content from "../../containers/Content";
import Section from "../../containers/Section";
import Footer from "../../containers/Footer";
import Banner from "../../components/Banner";
import Header from "../../containers/Header";
import Label from "../../components/Label";
import Strip from "../../components/Strip";

import POOP_ONE from "../../assets/png/5.png";
import POOP_TWO from "../../assets/png/6.png";
import POOP_FOUR from "../../assets/png/7.png";
import POOP_THREE from "../../assets/png/8.png";

import { analytics, logButtonPressed } from "../../clients/firebase";

// Homes

const Home = () => {
    const [isOpenSubscribeDialog, setIsOpenSubscribeDialog] = useState(false);
    const [isSaleActive, setIsSaleActive] = useState(true);
    const [prevMsg, setPrevMsg] = useState(null);
    const teamRef = useRef(null);
    const buyRef = useRef(null);

    const handleOnClick = (e) => {
        e.preventDefault();
        logButtonPressed("dropping_soon");
        // setIsOpenSubscribeDialog(true);
    };

    // useEffect(() => {
    //     let aTimeout = setTimeout(() => {
    //         // Check if the user has subscribed or not
    //         let isSubscribed = localStorage.getItem("isSubscribed");
    //         if (isSubscribed !== "true") {
    //             setIsOpenSubscribeDialog(true);
    //         }
    //     }, 5000);
    //     return () => {
    //         clearTimeout(aTimeout);
    //     };
    // }, []);

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Header
                buyRef={buyRef}
                teamRef={teamRef}
                isSaleActive={isSaleActive}
            />
            <Strip text="Warning! Don't be fooled by scams. We are the only authentic seller of WORTHLESSS pieces of sh!t™" />
            <DiscordStrip
                text="Click here to join 1620+ others in our Discord!"
                className="discord"
            />
            <Content>
                <Banner />
                <Section>
                    <div className="row" ref={buyRef}>
                        <div className="column">
                            <Label
                                className="presenting"
                                color="var(--color-white)"
                                content={"WELCOME TO"}
                                margin="0"
                            />
                            <Label
                                className="pieces-of-shit"
                                type={"supertitle"}
                                color="var(--color-white)"
                                content={"WORTHLESSS"}
                                margin="0 0 var(--spacing-3x)"
                            />
                            <Label
                                type="body"
                                color="var(--color-white)"
                                margin="0 0 var(--spacing-16x) 0"
                            >
                                <b>
                                    <i>WORTHLESSS pieces of sh!t™ </i>
                                </b>{" "}
                                is a collection of 10,002 NFTs — unique digital
                                collectibles living on the Ethereum blockchain.
                                <br />
                                <br />
                                Holding a{" "}
                                <b>
                                    <i>WORTHLESSS piece of sh!t™ </i>
                                </b>{" "}
                                allows you to participate in coordinating the{" "}
                                <a
                                    target="_blank"
                                    href="https://etherscan.io/address/0x426884c1F0A87711fbBca056E051F28D07F78bE0"
                                >
                                    {" "}
                                    <b>
                                        <i>WORTHLESSS</i>
                                    </b>{" "}
                                    Community Treasury
                                </a>
                                . The treasury will be seeded with 20 ETH when
                                the public sale sells out! WPOS™ holders can
                                vote for experiences, activations and campaigns
                                that benefit the community.
                                <br />
                                <br />
                                So, what is{" "}
                                <b>
                                    <i>WORTHLESSS</i>
                                </b>
                                ? Well, we’re a home base for thinkers, artists,
                                and misfits bold enough to break away from the
                                establishment and hold up a middle finger to
                                centralization. We’re energized by web3
                                innovations along with cryptic creativity, and
                                are ecstatic to join the charge as the world
                                transforms into a crypto economy. Our goal is to
                                unite dope, like-minded people who are
                                emboldened by the power of the blockchain and
                                bring them together — both online and off — for
                                one hell of a good time.
                            </Label>
                        </div>
                        <FourByFour marginBottom="var(--spacing-16x)" />
                    </div>
                    <div className="row bonding">
                        <div className="column distribution">
                            <Label
                                type="subtitle"
                                content={"Fair distribution"}
                                margin="0"
                            />
                            <Label
                                type="subtext"
                                content={"(BONDING CURVES ARE CRAP)"}
                                margin="0"
                            />
                            <Label
                                type="body"
                                className="bonding"
                                color="var(--color-white)"
                            >
                                Our presale price is half off. Otherwise, there
                                are no bonding curves here. Buying a{" "}
                                <b>
                                    <i>piece of sh!t™ </i>
                                </b>
                                costs 0.04 ETH.
                            </Label>
                        </div>
                        <Label type="small" className="disclaimer">
                            Note: 25{" "}
                            <b>
                                <i>pieces of sh!t™ </i>
                            </b>{" "}
                            are being withheld from the sale. These will be used
                            for giveaways, random shit—and for the creators'
                            WPOS™ passports.
                        </Label>
                    </div>
                    {isSaleActive && (
                        <MintLinkButton
                            onToast={null}
                            onClick={handleOnClick}
                        />
                    )}
                    {!isSaleActive && (
                        <DroppingSoonButton
                            onToast={null}
                            onClick={handleOnClick}
                        />
                    )}
                </Section>
                <Section>
                    <div className="row">
                        <div className="column">
                            <Label
                                color="var(--color-white)"
                                content={"The specs"}
                                margin=""
                            />
                            <Label
                                type="body"
                                color="var(--color-white)"
                                className="specs"
                            >
                                Each{" "}
                                <b>
                                    <i>piece of sh!t™ </i>
                                </b>{" "}
                                is unique and programmatically generated from
                                over 200 backgrounds and 100+ possible traits,
                                including the sh!ts, stuff stuck in the sh!t,
                                stuff on and around the sh!t, and stuff hovering
                                over the sh!t. All{" "}
                                <b>
                                    <i>pieces of sh!t™ </i>
                                </b>{" "}
                                are dope, but some are rarer than others. <br />
                                <br />
                                The WPOS™ are stored as ERC-721 tokens on the
                                Ethereum blockchain and hosted on IPFS. (See{" "}
                                <a href="https://etherscan.io/address/0x3f7dfdcbffff27d79b566f803fdb2286fe494149">
                                    Record
                                </a>{" "}
                                and{" "}
                                <a href="https://docsss.worthlesss.com/provable-fairness">
                                    Proof
                                </a>
                                ). Purchasing a WPOS™ costs 0.04 ETH.
                                <br />
                                <br />
                                To access members-only areas in the future,
                                holders will need to be signed into their
                                Metamask Wallet.
                            </Label>
                        </div>
                        <OneByOne />
                    </div>
                </Section>
                <Section>
                    <div className="row">
                        <div className="column">
                            <Label
                                color="var(--color-white)"
                                content={"The Roadmap"}
                                margin=""
                            />
                            <Label
                                className="club"
                                type="body"
                                color="var(--color-white)"
                            >
                                <Label
                                    className=""
                                    type="body"
                                    margin="0 0 var(--spacing-10x) 0"
                                >
                                    Like many of you we started as fans of the
                                    space. As our interest grew, we quickly
                                    realized we wanted to participate. We
                                    decided to drop a collection that aims to be
                                    culturally relevant yet also calls out the
                                    absurdity of today's market.
                                    <br></br>
                                    <br></br>
                                    Each milestone we reach unlocks a dope new{" "}
                                    <b>
                                        <i>WORTHLESSS</i>
                                    </b>{" "}
                                    dimension.
                                </Label>
                                <div
                                    className="row roadmap"
                                    justifyContent="flex-start"
                                >
                                    <Label
                                        className="roadmap percent"
                                        type="body"
                                        margin="0 var(--spacing-16x) 0 0"
                                    >
                                        10%
                                    </Label>
                                    <Label className="detail" type="body">
                                        We airdrop 5 sh!ts — at random — to the
                                        first{" "}
                                        <b>
                                            <i>WORTHLESSS</i>
                                        </b>{" "}
                                        holders. Deuces for our day ones!
                                    </Label>
                                </div>
                                <div className="row roadmap">
                                    <Label
                                        className="roadmap percent"
                                        type="body"
                                        margin="0 var(--spacing-16x) 0 0"
                                    >
                                        25%
                                    </Label>
                                    <Label className="detail" type="body">
                                        Member-Exclusive{" "}
                                        <b>
                                            <i>WORTHLESSS</i>
                                        </b>{" "}
                                        Merch Store is launched with limited
                                        edition threads, gear, and everyday
                                        carry.
                                    </Label>
                                </div>
                                <div className="row roadmap">
                                    <Label
                                        className="roadmap percent"
                                        type="body"
                                        margin="0 var(--spacing-16x) 0 0"
                                    >
                                        50%
                                    </Label>
                                    <Label className="detail" type="body">
                                        Commission a larger-than-life WPOS™ to
                                        be literally (but carefully) air-dropped
                                        into a public space.
                                    </Label>
                                </div>
                                <div className="row roadmap">
                                    <Label
                                        className="roadmap percent"
                                        type="body"
                                        margin="0 var(--spacing-16x) 0 0"
                                    >
                                        75%
                                    </Label>
                                    <Label className="detail" type="body">
                                        Find and meet with the best DAO
                                        consultants out there so we can
                                        establish ways for WPOS™ holders to
                                        collectively vote on our{" "}
                                        <b>
                                            <i>WORTHLESSS</i>
                                        </b>{" "}
                                        future.
                                    </Label>
                                </div>
                                <div className="row roadmap">
                                    <Label
                                        className="roadmap percent"
                                        type="body"
                                        margin="0 var(--spacing-16x) 0 0"
                                    >
                                        100%
                                    </Label>
                                    <Label className="detail" type="body">
                                        Seed the{" "}
                                        <a
                                            target="_blank"
                                            href="https://etherscan.io/address/0x426884c1F0A87711fbBca056E051F28D07F78bE0"
                                        >
                                            WPOS™ Community Treasury
                                        </a>{" "}
                                        with 20 ETH and work together to choose
                                        and commit our very first{" "}
                                        <b>
                                            <i>WORTHLESSS</i>
                                        </b>{" "}
                                        Deed. Think extravagant and excessive
                                        but also, charitable and fun!
                                    </Label>
                                </div>
                            </Label>
                        </div>
                    </div>
                </Section>
                <Section>
                    <div className="row">
                        <div className="column">
                            <Label
                                color="var(--color-white)"
                                content={"GET DAO(N) with us"}
                                margin=""
                            />
                            <Label
                                className="club"
                                type="body"
                                color="var(--color-white)"
                            >
                                When you buy a{" "}
                                <b>
                                    <i>piece of sh!t™</i>
                                </b>
                                , you are not just buying a provably-rare piece
                                of art. You are gaining the power to vote in
                                coordinating the{" "}
                                <a
                                    target="_blank"
                                    href="https://etherscan.io/address/0x426884c1F0A87711fbBca056E051F28D07F78bE0"
                                >
                                    {" "}
                                    <b>
                                        <i>WORTHLESSS</i>
                                    </b>{" "}
                                    Community Treasury
                                </a>
                                . Your{" "}
                                <b>
                                    <i>piece of sh!t™ </i>
                                </b>{" "}
                                will serve as your digital passport and open
                                both physical and digital doors for you.
                            </Label>
                        </div>
                    </div>
                </Section>
                <Section>
                    <div className="row" ref={teamRef}>
                        <div className="column">
                            <Label
                                color="var(--color-white)"
                                content={"The Team"}
                                margin=""
                            />
                            <Label
                                type="body"
                                color="var(--color-white)"
                                margin="var(--spacing-2x) 0 var(--spacing-16x) 0"
                            >
                                <b>
                                    <i>WORTHLESSS </i>
                                </b>{" "}
                                was created by four friends who set out to make
                                a splash in the world of NFTs. Our debut
                                collection—
                                <b>
                                    <i>pieces of sh!t™</i>
                                </b>
                                —was a way for us to test the waters, have some
                                fun, and most importantly make some cool sh!t.
                            </Label>
                            <TeamMembers />
                        </div>
                        <FourByFour
                            className="team"
                            imgOne={POOP_ONE}
                            imgTwo={POOP_TWO}
                            imgThree={POOP_THREE}
                            imgFour={POOP_FOUR}
                        />
                    </div>
                </Section>
            </Content>
            <Footer
                onRequestDialogOpen={(e) => setIsOpenSubscribeDialog(true)}
            />
            <Strip text="©2021 a WORTHLESSS product" />
            <SubscribeDialog
                open={isOpenSubscribeDialog}
                onClose={(e) => setIsOpenSubscribeDialog(false)}
            />
        </>
    );
};

export default Home;
