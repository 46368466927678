// Imports

import React from "react";

import { logButtonPressed } from "../../clients/firebase";
import Label from "../Label";

// Discord Strip

const DiscordStrip = ({ text, children, className }) => {
    const handleOnClick = () => {
        logButtonPressed("join_our_discord");
        window.open("https://discord.gg/dysVAagmS8");
    };

    return (
        <div className={`Strip Discord ${className}`} onClick={handleOnClick}>
            <Label type="exclamation">
                {text}
                {children}
            </Label>
        </div>
    );
};

export default DiscordStrip;
