// Imports

import { useState, useEffect } from "react";

import Strip from "../../Strip";

// Dropping Soon Button

const DroppingSoon = ({ onToast, onClick }) => {
    let connectedClassName = "";

    const [walletAddress, setWalletAddress] = useState(null);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");
    const [type, setType] = useState("info");

    const handleOnClick = async (e) => {
        if (typeof onClick === "function") {
            onClick(e);
        }
    };

    useEffect(() => {
        if (status && typeof onToast === "function") {
            onToast(status, type);
        }
    }, [status, onToast]);

    if (walletAddress) {
        connectedClassName = "connected";
    }

    return (
        <div className="Minter">
            <button
                id="mintButton"
                className="MintButton"
                onClick={(e) => handleOnClick(e)}
            >
                {!loading && (
                    <>
                        <i>PUBLIC SALE DEC. 29</i>
                    </>
                )}
                {loading && (
                    <>
                        <div class="loader" />
                    </>
                )}
            </button>
        </div>
    );
};

export default DroppingSoon;
