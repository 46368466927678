// Imports

import DiscordStrip from "../../components/DiscordStrip";
import Content from "../../containers/Content";
import Section from "../../containers/Section";
import Footer from "../../containers/Footer";
import Header from "../../containers/Header";
import Label from "../../components/Label";
import Strip from "../../components/Strip";

// Provenance

const Provenance = () => {
    return (
        <>
            <Header />
            <Strip text="Warning! Don't be fooled by scams. We are the only authentic seller of WORTHLESSS pieces of sh!t™" />
            <DiscordStrip
                text="Click here to join 1620+ others in our Discord!"
                className="discord"
            />
            <Content>
                <Section
                    className="legal"
                    padding="var(--spacing-16x) var(--spacing-8x)"
                >
                    <div className="row">
                        <div className="column">
                            <Label
                                color="var(--color-white)"
                                content={
                                    "WORTHLESSS piece of sh!t Provenance Record"
                                }
                                margin="0"
                            />
                            <Label type="legal" color="var(--color-white)">
                                <b>
                                    <i>WORTHLESSS pieces of sh!t™ </i>
                                </b>{" "}
                                is a collection of digital artworks (NFTs)
                                running on the Ethereum network. This website is
                                only an interface allowing participants to
                                exchange digital collectibles. Users are
                                entirely responsible for the safety and
                                management of their own private Ethereum wallets
                                and validating all transactions and contracts
                                generated by this website before approval.
                                Furthermore, as the{" "}
                                <b>
                                    <i>WORTHLESSS pieces of sh!t™ </i>
                                </b>{" "}
                                smart contract runs on the Ethereum network,
                                there is no ability to undo, reverse, or restore
                                any transactions.
                                <br />
                                <br />
                                This website and its connected services are
                                provided “as is” and “as available” without
                                warranty of any kind. By using this website you
                                are accepting sole responsibility for any and
                                all transactions involving
                                <b>
                                    <i> WORTHLESSS pieces of sh!t™ </i>
                                </b>{" "}
                                digital collectibles.
                            </Label>
                        </div>
                    </div>
                </Section>
            </Content>
            <Footer />
            <Strip text="©2021 an AFTERTHOUGHT product" />
        </>
    );
};

export default Provenance;
