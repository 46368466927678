// Imports

import { RiTwitterFill, RiDiscordFill, RiInstagramFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import React from "react";

import MailchimpFormContainer from "../../components/MailchimpFormContainer";
import { logLinkPressed } from "../../clients/firebase";
import Label from "../../components/Label";

// Footer

const Footer = ({ onRequestDialogOpen }) => {
    const handleSocialOnClick = (e, social) => {
        switch (social) {
            case "twitter": {
                logLinkPressed("twitter");
                window.open("https://www.twitter.com/worthlesssclub");
                break;
            }
            case "discord": {
                logLinkPressed("discord");
                window.open("https://discord.gg/dysVAagmS8");
                break;
            }
            case "instagram": {
                logLinkPressed("instagram");
                window.open("https://www.instagram.com/worthlesssclub");
                break;
            }
            default:
                break;
        }
    };

    return (
        <footer className="Footer">
            <div className="row">
                <MailchimpFormContainer
                    onRequestDialogOpen={onRequestDialogOpen}
                />
                <div className="column contacts">
                    <ul className="list row icons">
                        <li
                            className="list-item icon"
                            onClick={(e) => handleSocialOnClick(e, "twitter")}
                        >
                            <RiTwitterFill size={18} />
                        </li>
                        <li
                            className="list-item icon"
                            onClick={(e) => handleSocialOnClick(e, "discord")}
                        >
                            <RiDiscordFill size={18} />
                        </li>
                        <li
                            className="list-item icon"
                            onClick={(e) => handleSocialOnClick(e, "instagram")}
                        >
                            <RiInstagramFill size={18} />
                        </li>
                    </ul>
                    <ul className="list column link">
                        <Link to="/terms">
                            <Label
                                type="tiny"
                                content={"WPOS™ Terms & Conditions"}
                                margin="var(--spacing-2x) 0px"
                            />
                        </Link>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
