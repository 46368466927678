// Imports

import { Dialog } from "@blueprintjs/core";
import { useState } from "react";

import InstagramButton from "../buttons/Instagram";
import DiscordButton from "../buttons/Discord";
import TwitterButton from "../buttons/Twitter";
import Mailer from "../Mailer";
import Label from "../Label";

// Subscribe Dialog

const SubscribeDialog = ({ open, onClose }) => {
  const [subscribed, setSubscribed] = useState(false);

  const handleOnClose = (e) => {
    if (typeof onClose === "function") {
      setSubscribed(false);
      onClose();
    }
  };

  const handleSubscribe = () => {
    setSubscribed(true);
  };

  return (
    <Dialog className="DialogSubscribe" isOpen={open} onClose={handleOnClose}>
      <div className="body">
        {!subscribed && <Label className="supertitle">DON'T MISS OUT</Label>}
        {subscribed && (
          <Label className="supertitle" color="var(--color-green)">
            SUCCESS!
          </Label>
        )}
        {!subscribed && (
          <Label type="body" className="label">
            Be the first to know when these sh!ts drop. Join the waiting list
            for exclusive updates, early-members' benefits, and free{" "}
            <b>
              <i>WORTHLESSS pieces of sh!t™ </i>
            </b>{" "}
            airdrops.
          </Label>
        )}
        {subscribed && (
          <Label type="body" className="label">
            Now that you're subscribed be sure to join our Discord. Our
            community is growing by the day. If you don't have Discord, check us
            out on Twitter and Instagram.
          </Label>
        )}
      </div>
      <Mailer onSubscribe={handleSubscribe} />
      <div className="column buttons">
        <DiscordButton />
        <div className="row">
          <InstagramButton />
          <TwitterButton />
        </div>
      </div>
    </Dialog>
  );
};

export default SubscribeDialog;
